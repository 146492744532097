import React from 'react'

export default function DeleteModal ({
  onClose,
  loading,
  text,
  onSubmit,
  btnText,
  closeText
}) {
  return (
    <div className='modal-dialog'>
      <div className='modal-content'>
        <div className='modal-body mt-3'>
          <label className='form-label fs-4'>{text}</label>
        </div>
        <div className='d-flex align-items-center justify-content-end gap-2 px-4 pb-4'>
          <button
            type='button'
            className='btn btn-sm btn-white'
            onClick={() => onClose(false)}
          >
            {closeText || 'Close'}
          </button>
          <button
            className='btn btn-danger btn-sm d-flex align-items-center'
            type='button'
            disabled={loading}
            onClick={() => onSubmit()}
          >
            {loading && (
              <span className='spinner-border spinner-border-sm me-1' />
            )}
            {btnText}
          </button>
        </div>
      </div>
    </div>
  )
}
