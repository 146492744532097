import React from 'react'
import ReactTooltip from 'react-tooltip'
import { NavLink } from 'react-router-dom'
import { routePaths } from '../../../global/routePaths'

export default function ExtraHelpLinks () {
  // const { theme, setTheme } = useContext(DataProvider);
  // const themeRef = useRef();
  // const [isDropdown, setShowDropdown] = useState(false);
  // useOnClickOutside(themeRef, () => setShowDropdown(false));

  // const showDropDown = () => {
  //   setShowDropdown(!isDropdown);
  // }

  // useEffect(() => {
  //   const applyTheme = () => {
  //     let themeToApply = theme
  //     // Dynamically load the appropriate CSS
  //     const themeLink = document.getElementById('theme-style');
  //     const body = document.body;
  //     // Add a smooth transition effect
  //     body.style.transition = "background-color 0.5s, color 0.5s";
  //     console.log("themeLink=>",themeLink);
  //     if (themeLink) {
  //       themeLink.href =
  //         themeToApply === 'dark'
  //         ? '/css/theme-dark.css'
  //         : '/css/theme-light.css';
  //       setShowDropdown(false);
  //     }
  //   };
  //   applyTheme();
  // }, [theme]);
  return (
    <>
      <div className='navbar-vertical-footer-list'>
        {/* <div className="navbar-vertical-footer-list-item">
          <div className="dropdown dropup" ref={themeRef}>
            <button
              id="navbarVerticalThemeDropdown"
              className="btn btn-icon btn-ghost-secondary rounded-circle position-relative z-20"
              onClick={showDropDown}
              title="Switch Theme"
            >
              <i className="tio tio-brightness-3"></i>
            </button>
            {isDropdown && <div className="navbar-dropdown-menu-borderless dropdown-menu position-absolute show gap-3" style={{marginTop:"-150px", marginLeft:"-10px"}}>
              <button className={`dropdown-item ${theme === 'light' ? 'active' : ''} mt-1 p-2`} onClick={()=>setTheme('light')}>
                <i className="bi-brightness-high me-2" />
                Light (Default)
              </button>
              <button className={`dropdown-item ${theme === 'dark' ? 'active' : ''} mt-1 p-2`} onClick={()=>setTheme('dark')}>
                <i className="bi-moon me-2" />
                Dark
              </button>

            </div>}
          </div>
        </div> */}
        <div className='navbar-vertical-footer-list-item'>
          <NavLink
            className='js-hs-unfold-invoker btn btn-icon btn-ghost-secondary rounded-circle'
            to='/help'
            target='_blank'
            title='get help'
            data-tip
            data-for='sidebar-community-help'
            rel='noreferrer'
          >
            <i className='tio-support-vs' />
          </NavLink>
        </div>
        <div className='navbar-vertical-footer-list-item'>
          <a
            className='js-hs-unfold-invoker btn btn-icon btn-ghost-secondary rounded-circle'
            href='https://webapp.gumlet.com/dashboard/canny-redirect/'
            target='_blank'
            title='Make a Suggestion'
            data-tip
            data-for='sidebar-suggestion'
            rel='noreferrer'
          >
            <i className='tio-chat' />
          </a>
        </div>
        <div className='navbar-vertical-footer-list-item'>
          <NavLink
            to={routePaths.review}
            className='js-hs-unfold-invoker btn btn-icon btn-ghost-secondary rounded-circle'
            data-tip
            data-for='sidebar-rewiew'
          >
            <i className='tio-quotes' />
          </NavLink>
        </div>
        <ReactTooltip id='sidebar-community-help' place='left' effect='solid'>
          Get Help
        </ReactTooltip>
        <ReactTooltip id='sidebar-suggestion' place='left' effect='solid'>
          Make a Suggestion
        </ReactTooltip>
        <ReactTooltip id='sidebar-rewiew' place='top' effect='solid'>
          Write a Review
        </ReactTooltip>
      </div>
    </>
  )
}
