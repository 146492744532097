import { routePaths } from 'global/routePaths'
import { Dot } from 'react-bootstrap-icons'

export const SidebarData = [
  {
    tagtitle: 'Video',
    replacer: <Dot />
  },
  {
    title: 'Overview',
    path: routePaths.video.dashboard,
    isToggle: 'false',
    icon: <i className='tio tio-home nav-icon fs-3' />
  },
  {
    title: 'Video',
    icon: <i className='tio tio-youtube nav-icon' />,
    subNav: [
      {
        title: 'Video Library',
        path: routePaths.video.cms.manage
      },
      {
        title: 'Import Videos',
        path: routePaths.video.importers.base
      },
      {
        title: 'Usage Analytics',
        path: routePaths.video.analytics
      },
      {
        title: 'Advanced',
        subNav: [
          {
            title: 'Reports',
            path: routePaths.video.reports.allreports
          },
          {
            title: 'Alerts',
            path: routePaths.video.alerts.allalerts
          },
          {
            title: 'Collections',
            path: routePaths.video.sources.allsources
          },
          {
            title: 'Profiles',
            path: routePaths.video.profiles.allprofiles
          }
        ]
      }
    ]
  },
  {
    title: 'Live Streams',
    icon: <i className='tio tio-video-camera-outlined nav-icon' />,
    subNav: [
      {
        title: 'Stream Manager',
        path: routePaths.stream.assets.base
      },
      {
        title: 'Collections',
        path: routePaths.stream.sources.allsources
      },
      {
        title: 'Usage Analytics',
        path: routePaths.stream.analytics
      }
    ]
  },
  {
    title: 'Analytics',
    icon: <i className='tio tio-chart-bar-2 nav-icon fs-3' />,
    subNav: [
      {
        title: 'Dashboard',
        path: routePaths.insights.boards.base.route
      },
      {
        title: 'Playbacks',
        path: routePaths.insights.views.base.route
      },
      {
        title: 'Properties',
        path: routePaths.insights.properties.base.route
      }
    ]
  },
  {
    tagtitle: 'Image',
    replacer: <Dot />
  },
  {
    title: 'Overview',
    path: routePaths.image.dashboard,
    isToggle: 'false',
    icon: <i className='tio tio-dashboard nav-icon fs-3' />
  },
  {
    title: 'Images',
    icon: <i className='tio tio-image nav-icon fs-3' />,
    subNav: [
      {
        title: 'Sources',
        path: routePaths.image.sources.allsources
      },
      {
        title: 'Purge Cache',
        path: routePaths.image.cache
      },
      {
        title: 'Analytics',
        path: routePaths.image.analytics
      },
      {
        title: 'Monitoring',
        subNav: [
          {
            title: 'Reports',
            path: routePaths.image.reports.allreports
          },
          {
            title: 'Alerts',
            path: routePaths.image.alerts.allalerts
          }
        ]
      }
    ]
  },
  {
    tagtitle: 'Settings'
    // replacer: <Dot />,
  },
  {
    title: 'Organization',
    icon: <i className='tio tio-globe nav-icon fs-3' />,
    subNav: [
      {
        title: 'Billing',
        path: routePaths.organization.billing.base
      },
      {
        title: 'Manage Users',
        path: routePaths.organization.accounts.base
      },
      {
        title: 'Webhooks',
        path: routePaths.organization.webhooks.base
      },
      {
        title: 'DRM Credentials',
        path: routePaths.organization.drm
      },
      {
        title: 'Coupons',
        path: routePaths.organization.coupons
      }
    ]
  },
  {
    title: 'User',
    icon: <i className='tio tio-user nav-icon fs-3' />,
    subNav: [
      {
        title: 'Profile',
        path: routePaths.user.profile
      },
      {
        title: 'API Keys',
        path: routePaths.user.apikey
      },
      {
        title: 'Active Sessions',
        path: routePaths.user.sessions
      },
      {
        title: 'Logout',
        onclick: true
      }
    ]
  },
  {
    title: 'Help Center',
    path: '/help',
    icon: <i className='tio tio-help nav-icon' />
  }
]
