import React, {
  useContext,
  useEffect,
  useRef
} from 'react'
import DataProvider from 'context/DataContext'
import useOnClickOutside from 'utils/outsideClick'
import useWindowSize from 'utils/windowSize'
import { useLogout } from 'hooks/auth/useLogout'
import { SidebarData } from './data'
import SubMenu from './SubMenu'
import ExtraHelpLinks from './Extras/extraHelp'
import UpgradeNudge from './UpgradeNudge'
import { useLocation } from 'react-router-dom'

export default function Sidebar () {
  const { theme, setOnSidebarClick, userData, orgData } = useContext(DataProvider)
  const handleLogout = useLogout()

  const location = useLocation()

  const size = useWindowSize()
  const sidebarRef = useRef(null)

  useOnClickOutside(sidebarRef, () => setOnSidebarClick(false))

  const itemProps = size.width < 1200 ? { ref: sidebarRef } : {}

  useEffect(() => {
    if (location) {
      setOnSidebarClick(false)
    }
  }, [location])

  if (!orgData || !userData) {
    // Ensure orgData is populated before rendering
    return null // Or a loading spinner
  }

  const shouldShowUpgradeNudge = !(
    userData?.roles?.some((role) =>
      ['video-readonly', 'developer'].includes(role)
    ) ||
    orgData?.plan_data?.video?.plan === 'enterprise' ||
    orgData?.stripe_account_loc === 'ind'
  )

  return (
    <>
      <aside
        {...itemProps}
        className='js-navbar-vertical-aside navbar navbar-vertical-aside navbar-vertical navbar-vertical-fixed navbar-expand-xl navbar-bordered bg-white navbar-white navbar-vertical-aside-initialized'
      >
        <div className='navbar-vertical-container'>
          <div className='navbar-vertical-footer-offset'>
            <a className='navbar-brand' href='/'>
              {theme === 'dark'
                ? (
                  <img
                    className='navbar-brand-logo'
                    src='https://assets.gumlet.io/assets/gumlet-logo-white-font.png?w=240&format=auto'
                    alt='Logo'
                  />
                  )
                : (
                  <img
                    className='navbar-brand-logo'
                    src='https://assets.gumlet.io/assets/gumlet-logo-black-font.png?w=240&format=auto'
                    alt='Logo'
                  />
                  )}
              <img
                className='navbar-brand-logo-mini'
                src='https://assets.gumlet.io/assets/round-logo.png?w=100&format=auto'
                alt='Logo'
              />
            </a>
            <div
              className='navbar-vertical-content'
              style={{
                paddingBottom: '120px'
              }}
            >
              <div
                id='navbarVerticalMenu'
                className='nav nav-pills nav-vertical card-navbar-nav'
              >
                <>
                  {SidebarData.map((item, index) => {
                    return (
                      <SubMenu
                        item={item}
                        key={index}
                        theme={theme}
                        handleLogout={handleLogout}
                        userData={userData}
                        orgData={orgData}
                      />
                    )
                  })}
                </>
              </div>
            </div>
            {/* navbar footer */}
            {shouldShowUpgradeNudge && (
              <UpgradeNudge orgData={orgData} theme={theme} />
            )}
            <div
              className='navbar-vertical-footer justify-content-start bg-white'
            >
              <ExtraHelpLinks />
            </div>
          </div>
        </div>
      </aside>
      <div className='js-navbar-vertical-aside-toggle-invoker navbar-vertical-aside-mobile-overlay' />
    </>
  )
}
